<template>
  <div>
    <div
      class="md-layout md-alignment-top-space-between"
      v-if="articles && articles.totalElements > 0"
    >
      <article-content-list
        class="
          md-layout-item md-size-20 md-medium-size-30 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': hideList }"
        :style="`height: ${height - 15}px`"
        @selected="selected"
      />
      <article-content-info
        class="
          md-layout-item md-size-75 md-medium-size-65 md-small-size-100
          content-info
        "
        :class="{ 'md-small-hide': !hideList }"
        :style="`height: ${height - 15}px`"
        @back="back"
      />
    </div>
    <div v-else>
      <md-empty-state
        md-rounded
        md-icon="add"
        :md-label="$t('no_articles_created')"
      >
        <md-button class="md-raised md-primary" :to="{ name: 'ArticleCreate' }">
          {{ $t("create") }}
        </md-button>
      </md-empty-state>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleContentInfo from "./ArticleContentInfo.vue";
import ArticleContentList from "./ArticleContentList.vue";
export default {
  name: "ArticleContent",
  components: {
    ArticleContentList,
    ArticleContentInfo,
  },
  props: {
    height: {
      type: Number,
      default: 100,
    },
  },

  data() {
    return {
      hideList: false,
    };
  },

  computed: {
    ...mapGetters({
      articles: "getArticles",
    }),
  },

  methods: {
    selected() {
      this.hideList = true;
    },
    back() {
      this.hideList = false;
    },
  },
};
</script>

<style scoped>
.content-info {
  margin-top: 15px;
  height: 100px;
  overflow-y: auto;
  padding-right: 15px;
}
</style>