<template>
  <div>
    <!-- TOP BTNS -->
    <md-button class="md-raised md-icon-button mg-left-0" @click="reloadData">
      <font-awesome-icon icon="redo" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      @click="onSearchClick"
    >
      <font-awesome-icon icon="search" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'ArticleCreate' }"
    >
      <font-awesome-icon icon="plus" />
    </md-button>

    <md-button
      class="md-raised md-icon-button mg-left-0"
      :to="{ name: 'ArticleImport' }"
    >
      <font-awesome-icon icon="upload" />
    </md-button>

    <article-search ref="article-search" v-show="searchVisible" />

    <md-progress-bar v-if="loading" md-mode="indeterminate" />
    <article-content
      v-else
      ref="content"
      :style="contentHeight"
      :height="height"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleContent from "../../components/article/ArticleContent.vue";
import {
  ARTICLE_LOAD,
  ARTICLE_RELOAD,
  ARTICLE_UNLOAD,
  TOOLBAR_TITLE,
} from "../../store/actions.types";
import ArticleSearch from "../../components/article/ArticleSearch.vue";

export default {
  name: "Article",
  components: {
    ArticleContent,
    ArticleSearch,
  },
  computed: {
    ...mapGetters({
      articles: "getArticles",
    }),
  },

  data() {
    return {
      loading: true,
      contentHeight: "",
      height: 100,
      searchVisible: false,
    };
  },

  mounted() {
    this.$store.dispatch(TOOLBAR_TITLE, "article");
    this.loadData();
    window.addEventListener("resize", this.calculateHeight);
  },

  beforeDestroy() {
    this.$store.dispatch(ARTICLE_UNLOAD);
    window.removeEventListener("resize", this.calculateHeight);
  },

  methods: {
    async loadData() {
      await this.$store.dispatch(ARTICLE_LOAD);
      this.loading = false;

      this.$nextTick(() => {
        this.calculateHeight();
      });
    },

    reloadData() {
      this.$store.dispatch(ARTICLE_RELOAD);
    },

    calculateHeight() {
      let min = this.$refs.content.$el.getBoundingClientRect().top;
      let max = window.innerHeight;
      let size = max - min - 17;
      this.height = size;
      this.contentHeight = `height: ${size}px;`;
    },

    onSearchClick() {
      this.searchVisible = !this.searchVisible;
      this.$nextTick(() => {
        this.calculateHeight();
        if (this.searchVisible) {
          this.$refs["article-search"].$refs[
            "article-search-input"
          ].$el.focus();
        }
      });
    },
  },
};
</script>
