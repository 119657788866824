<template>
  <div v-if="selectedArticle.id">
    <md-button
      class="md-raised md-icon-button mg-left-0 btn-back"
      @click="back"
    >
      <font-awesome-icon icon="arrow-left" />
    </md-button>

    <article-form
      :article="selectedArticle"
      :disabled="submiting"
      @onSubmit="onSubmit"
    />

    <md-button
      class="md-raised md-accent md-icon-button mg-left-0 remove"
      @click="remove"
    >
      <font-awesome-icon icon="trash" />
    </md-button>
  </div>
  <div v-else>
    <md-empty-state
      md-rounded
      md-icon="reorder"
      :md-label="$t('first_select_a_article')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Article } from "../../common/api/api.service";
import {
  ARTICLE_RELOAD,
  ARTICLE_SELECT_DETAILS,
  CONFIRM_SHOW,
} from "../../store/actions.types";
import ArticleForm from "./ArticleForm.vue";
export default {
  name: "ArticleContentInfo",
  components: {
    ArticleForm,
  },
  data() {
    return {
      isEditing: false,
      submiting: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedArticle: "getSelectedArticle",
    }),
  },
  methods: {
    onSubmit(article) {
      this.submiting = true;
      Article.put(article)
        .then(() => {
          this.$toast.success(this.$t("updated!"));
          this.$store.dispatch(ARTICLE_RELOAD);
          this.$store.dispatch(ARTICLE_SELECT_DETAILS, {});
          this.isEditing = false;
          this.$emit("back");
        })
        .finally(() => {
          this.submiting = false;
        });
    },

    remove() {
      this.$store.dispatch(CONFIRM_SHOW, {
        warning: true,
        title: "remove?",
        confirm: "remove",
        cancel: "cancel",
        onConfirm: () => {
          Article.delete(this.selectedArticle.id).then(() => {
            this.$emit("back");
            this.$store.dispatch(ARTICLE_RELOAD);
            this.$store.dispatch(ARTICLE_SELECT_DETAILS, {});
          });
        },
      });
    },

    back() {
      this.$emit("back");
    },
  },
};
</script>

<style scoped>
@media (min-width: 959px) {
  .btn-back {
    display: none;
  }
}
.btn-back {
  margin-bottom: 10px;
}
.remove {
  margin-top: 10px;
}
</style>
