<template>
  <div>
    <md-list>
      <md-list-item
        v-for="article in articles.content"
        :key="article.id"
        @click="selectArticle(article)"
        :class="{ selected: article.id === selectedArticle.id }"
      >
        <div class="md-list-item-text">
          <span>
            {{ article.reference }}
            <span v-if="article.description">- {{ article.description }}</span>
          </span>
          <span>{{ formatDateTime(article.createdDate) }} </span>
        </div>
      </md-list-item>

      <md-list-item
        v-if="!articles.last"
        class="load-more"
        v-observe-visibility="visibilityChange"
        @click="loadMore"
      >
        {{ $t("load_more") }}...
      </md-list-item>
    </md-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { DateMixin } from "../../common/mixins/DateMixin";
import {
  ARTICLE_LOAD_MORE,
  ARTICLE_SELECT_DETAILS,
} from "../../store/actions.types";
export default {
  name: "ArticleContentList",
  mixins: [DateMixin],
  computed: {
    ...mapGetters({
      articles: "getArticles",
      selectedArticle: "getSelectedArticle",
    }),
  },
  data() {
    return {
      loading: false,
    };
  },

  methods: {
    selectArticle(article) {
      this.$store.dispatch(ARTICLE_SELECT_DETAILS, article);
      this.$emit("selected");
    },
    visibilityChange(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;
        this.$store.dispatch(ARTICLE_LOAD_MORE).finally(() => {
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.selected {
  background: #ebebeb;
}
.load-more .md-list-item-container >>> .md-list-item-content {
  justify-content: center;
}
.md-list-item {
  margin: 2px 0;
}
</style>